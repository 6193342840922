import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Header from '../components/Header';

const ButtonStyles = styled.div`
  padding: 1em;
  background-color: var(--darkPurple);
  border: solid 1px #000;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export default function Join({ location }) {
  const data = useStaticQuery(graphql`
    query JoinQuery {
      joinSettings: allSanityJoin {
        nodes {
          headline
          body
          contactEmail
        }
      }
    }
  `);
  const settings = data.joinSettings.nodes[0];
  return (
    <>
      <SEO location={location} title="Join the coalition" />

      <Header uniqueHeadline={settings.headline} />
      <main>
        <section className="center">
          <h1>{parse(settings.headline)}</h1>
        </section>
        <p>{parse(settings.body)}</p>
        <ButtonStyles>
          <a href={`mailto:${settings.contactEmail}`}>Join the coalition</a>
        </ButtonStyles>
      </main>
    </>
  );
}
